
import React from 'react'
import Layout from '../../components/layout'
import Nav from '../../components/nav'
import SEO from '../../components/seo'

import { ErrorBoundaryWithLogger } from '../../frontend-lib/Log'

const Privacy = () => {
    const lang = "en"
    return (
        <ErrorBoundaryWithLogger serverUrl={process.env.GATSBY_SERVER_URL} logName="website">
            <Layout lang={lang}>
                <SEO
                    title={"Optiwe - Privacy Policy"}
                    description={"Privacy policy of the service"}
                    lang={"en"}
                    locales={[
                        { hreflang: 'en', href: 'https://optiwe.com/legal/privacy' },
                        { hreflang: 'x-default', href: 'https://optiwe.com/legal/privacy' }
                    ]}
                />

                <main className="container-fluid ow-banner">
                    {/*
                    ^*********
                    * HEADER *
                    **********
                    */}
                    <Nav
                        lang={lang}
                        showButtons
                    />

                    <div className="container ow-banner__content">
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12 ow-banner__home-content">
                                <h1>Optiwe, Inc. Terms and Conditions</h1>
                                <h2>Effective May 1st, 2020</h2>
                                <p>
                                    Please read these Terms carefully. By using Optiwe or signing up for an account, you’re agreeing to
                                    these Terms, which will result in a legal agreement between you and Optiwe (“Agreement”). We’ll
                                    start with the basics, including a few definitions that should help you understand these Terms.
                                    Optiwe (“Optiwe,” “we,” or “us”) is an AI customer service platform (the “App”) that allows you to
                                    improve and optimize customer service operations. The App is offered through our websites,
                                    including https://www.optiwe.com and https://app.optiwe.com, and any other website,
                                    chrome-extension or mobile application owned, operated or controlled by us (we’ll collectively refer
                                    to these as the “Service”). Optiwe is a Delaware Corporation whose legal name is Optiwe, Inc.
                                    Optiwe has employees, independent contractors, and representatives (“our Team”). As a customer of
                                    the Service or a representative of an entity that’s a customer of the Service, you’re a “Member”
                                    according to this Agreement (or “you”).
                                    These Standard Terms of Use define the terms and conditions under which you’re allowed to use the
                                    Service in accordance with the Agreement, and how we’ll treat your account while you’re a Member.
                                    If you don’t agree to these Terms, you must immediately discontinue your use of the Service.
                                    If you have any questions about our Terms, feel free to contact us at info@optiwe.com
                                </p>
                                <h3>1. Eligibility</h3>
                                <p>
                                    In order to use the Service, you must:
                                </p>
                                <p>
                                    1. be at least eighteen (18) years old and able to enter into contracts;
                                </p>
                                <p>
                                    2. complete the account registration process;
                                </p>
                                <p>
                                    3. agree to these Terms;
                                </p>
                                <p>
                                    4. provide true, complete, and up-to-date contact and billing information;
                                </p>
                                <p>
                                    5. not be based in Cuba, Iran, North Korea, Syria, or any other territory that is subject to a U.S.
                                    government embargo, or that has been designated by the U.S. government as a
                                    “terrorist-supporting” country; and
                                </p>
                                <p>
                                    6. not be listed on any U.S. government list of prohibited or restricted persons.
                                </p>
                                <p>
                                    By using the Service, you represent and warrant that you meet all the requirements listed above, and
                                    that you won’t use the Service in a way that violates any laws or regulations. Note that by
                                    representing and warranting, you are making a legally enforceable promise.
                                    Optiwe may refuse service, close accounts of any Members, and change eligibility requirements at
                                    any time.
                                </p>
                                <h3>2. Term</h3>
                                <p>
                                    When you sign up for an account and agree to these Terms, the Agreement between you and
                                    Optiwe is formed, and the term of the Agreement (the “Term”) will begin. The Term will continue for
                                    as long as you have a Optiwe account or until you or we terminate the Agreement in accordance
                                    with these Terms, whichever happens first. If you sign up for an account on behalf of a company or
                                    other entity, you represent and warrant that you have the authority to accept these Terms and enter
                                    into the Agreement on its behalf.
                                </p>
                                <h3>3. Changes</h3>
                                <p>
                                    We may change any of the Terms at any time.. Unless you terminate your account, the new Terms
                                    will be effective immediately upon posting and apply to any continued or new use of the Service.
                                </p>
                                <h3>4. Account Disputes</h3>
                                <p>
                                    We don’t know the inner workings of your organization or the nature of your personal relationships.
                                    You won’t request access to or information about an account that’s not yours, and you’ll resolve any
                                    account-related disputes directly with the other party. We decide who owns an account based on a
                                    number of factors, including the content in that account, and the contact and profile information
                                    listed for that account. In cases where differing contact and profile information is present or we are
                                    unable to reasonably determine ownership, we’ll require you to resolve the matter through proper
                                    channels outside of Optiwe.
                                    When a dispute is identified, we may suspend any account associated with the dispute, including
                                    disabling login and sending capabilities, to protect the security and privacy of the data held within
                                    the account until the dispute is properly resolved.
                                </p>
                                <h3>5. Payments</h3>
                                <p>
                                    As long as you’re a Member with a Monthly Plan or otherwise have an outstanding balance with us,
                                    you’ll be using Stripe payments platform (https://stripe.com) and we’ll not store your credit or debit
                                    card information. You’ll replace the information for any card that expires with information for a valid
                                    one. If your card is automatically replaced with a new card by a payment processor, you
                                    acknowledge and agree that we’re authorized to deduct any charges on your account against the
                                    new card. Anyone using a card represents and warrants that they are authorized to use that card,
                                    and that any and all charges may be billed to that card and won’t be rejected. If we’re unable to
                                    process your card order, we’ll try to contact you by email and may suspend your account until your
                                    payment can be processed.
                                </p>
                                <h3>6. Billing Changes</h3>
                                <p>
                                    We may change our fees, including our charges for Monthly Plans at any time by posting a new
                                    pricing structure to our Optiwe Site or in your account and/or sending you a notification by email.
                                </p>
                                <h3>7. Feedback and Proprietary Rights</h3>
                                <p>
                                    We own all proprietary rights in the Service, including, but not limited to, patents, trademarks,
                                    service marks, trade secrets, copyrights, and other intellectual property rights. You will respect our
                                    proprietary rights in the Service.
                                    You represent and warrant that you either own or have permission to use all of the material, content,
                                    data, and information (including your personal information and the personal information of others)
                                    you submit to Optiwe in the course of using the Service or which Optiwe retrieves or accesses at
                                    your direction or with your permission (“Content”). You retain ownership of the Content that you
                                    upload to the Service. We may use or disclose your Content (including any personal information
                                    therein) only as described in these Terms and our Privacy Policy.
                                    You may provide or we may ask you to provide suggestions, comments, input or other feedback
                                    (“Feedback”) regarding the Service. If you provide us with any Feedback, then you grant us a
                                    royalty-free, non-exclusive, worldwide, sublicensable, and transferable license to use, reproduce,
                                    publicly display, distribute, modify, and publicly perform the Feedback as we see fit. Any Feedback
                                    you choose to provide is given entirely voluntarily. You understand that you will not receive any
                                    compensation for your Feedback, and that we may use any Feedback you provide to improve the
                                    Service or to develop new features and services.
                                </p>
                                <h3>8. Privacy Policy</h3>
                                <p>
                                    Your privacy is important to us. Please read our Privacy Policy for information regarding how we
                                    collect, use, and disclose your Content and personal information and protect your privacy when you
                                    use the Service.
                                </p>
                                <h3>9. Compliance with Laws</h3>
                                <p>
                                    You represent and warrant that your use of the Service will comply with all applicable laws and
                                    regulations. You’re responsible for determining whether the Service is suitable for you to use in light
                                    of your obligations under any regulations like HIPAA, GLB, Data Protection Laws, United States
                                    export control laws and regulations and economic sanctions laws and regulations (“U.S. Export
                                    Control Laws and Regulations”), or other applicable laws. If you’re subject to regulations (like
                                    HIPAA) and you use the Service, then we won’t be liable if the Service doesn’t meet those
                                    requirements. You may not use the Service for any unlawful or discriminatory activities, including
                                    acts prohibited by the Federal Trade Commission Act, Fair Credit Reporting Act, Equal Credit
                                    Opportunity Act, Children's Online Privacy Protection Act, or any other applicable laws.
                                    If you collect any personal information pertaining to a minor and store such information within your
                                    Optiwe account, you represent and warrant that you have obtained valid consent for such activities
                                    according to the applicable laws of the jurisdiction in which the minor lives.
                                    In addition, if you are subject to EU Data Protection Law (as defined in the Data Processing
                                    Addendum), you acknowledge and agree that we have your prior written authorization to respond, at
                                    our discretion, to any data subject access requests we receive from your contacts made under EU
                                    Data Protection Law, or, alternatively, we may direct any such contacts to you so that you can
                                    respond to the request accordingly.
                                </p>
                                <h3>10. Export Controls</h3>
                                <p>
                                    The software that supports the App (the “Software”) is subject to U.S. Export Control Laws and
                                    Regulations. Export laws are set up by the U.S. government to keep certain goods and services from
                                    reaching other countries, usually because of security concerns or trade agreements. None of the
                                    Software may be downloaded or otherwise exported or re-exported in violation of U.S. Export
                                    Control Laws and Regulations and any other applicable export laws and regulations (collectively,
                                    “Export Control Laws”). Therefore, you agree that you won’t, directly or indirectly through a third
                                    party, allow the Software to be accessed or generated from within, or distributed or sent to, any
                                    prohibited or embargoed country as mentioned in any Export Control Laws. In addition, you certify
                                    that neither you nor any principals, officers, directors, or any person or entity you know to be directly
                                    involved with the use of the Service is designated on any U.S. government list of prohibited or
                                    restricted persons.
                                </p>
                                <h3>11. Limitation of Liability</h3>
                                <p>
                                    To the maximum extent permitted by law, you acknowledge and agree that (i) you assume full
                                    responsibility for any loss that results from your use of the Service, including any downloads from
                                    the Optiwe Site; (ii) we and our Team won’t be liable for any indirect, punitive, special, or
                                    consequential damages under any circumstances, even if they’re based on negligence or we’ve been
                                    advised of the possibility of those damages; and (iii) in any calendar month, our total liability to you
                                    arising under or in connection with the Agreement — whether in contract, tort (including negligence),
                                    breach of statutory duty, or otherwise — will be no more than what you paid us for the Service the
                                    preceding month.
                                    For the avoidance of doubt, in no instance will we or our Team be liable for any losses or damages
                                    you suffer if you use the Service in violation of these Terms, regardless of whether we terminate or
                                    suspend your account due to such violation.
                                </p>
                                <h3>12. No Warranties</h3>
                                <p>
                                    To the maximum extent permitted by law, we provide the Service as-is. This means that, except as
                                    expressly stated in these Terms, we don’t provide warranties, conditions, or undertakings of any kind
                                    in relation to the Service, either express or implied. This includes, but isn’t limited to, warranties of
                                    merchantability and fitness for a particular purpose, which are, to the fullest extent permitted by law,
                                    excluded from the Agreement. Since Members use the Service for a variety of reasons, we can’t
                                    guarantee that it’ll meet your specific needs.
                                </p>
                                <h3>13. Indemnity</h3>
                                <p>
                                    You agree to indemnify and hold us and our Team harmless from any losses, including legal fees and
                                    expenses that directly or indirectly result from any claims you make that aren’t allowed under these
                                    Terms due to a “Limitation of Liability” or other provision. (Indemnity is an agreement to compensate
                                    someone for a loss.) You also agree to indemnify and hold us harmless from any losses, including
                                    legal fees and expenses, that directly or indirectly result from (i) your Content, (ii) your use of the
                                    Service, (iii) your violation of any laws or regulations, (iv) third-party claims that you or someone
                                    using your password did something that, if true, would violate any of these Terms, (v) any
                                    misrepresentations made by you, or (vi) a breach of any representations or warranties you’ve made
                                    to us.
                                </p>
                                <h3>14. Equitable Relief</h3>
                                <p>
                                    Your violation of these Terms may cause irreparable harm to us and our Team. Therefore, we have
                                    the right to seek injunctive relief or other equitable relief if you violate these Terms (meaning we may
                                    request a court order to stop you).
                                </p>
                                <h3>15. Disclaimers</h3>
                                <p>
                                    We and our Team aren’t responsible for the behavior of any third parties, agencies, linked websites,
                                    or other Members, including third-party applications, products, or services for use in connection with
                                    the Service (each, a “Third-Party Integration”). Your use of any Third-Party Integration and rights
                                    with respect to such Third-Party Integration are solely between you and the applicable third party.
                                    We are not responsible for the privacy, security or integrity of any Third-Party Integration or the
                                    practices and policies of any Third-Party Integration. We make no warranties of any kind and assume
                                    no liability of any kind for your use of any Third-Party Integration.
                                </p>
                                <h3>16. Entire Agreement</h3>
                                <p>
                                    These Terms you’ve agreed to by enabling make up the entire agreement between us in relation to
                                    its subject matter and supersede all prior agreements, representations, and understandings.
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
            </Layout>
        </ErrorBoundaryWithLogger>
    )
}
export default Privacy
